.home {
    display: flex;
    justify-content: space-between;

    
}
.left{
    padding: 2rem;
    padding-top: 1.5rem;
    flex:3;
    display: flex ;
    gap: 2rem;
    flex-direction: column;

}
.right{
    flex:1;
    position : relative;
    background-color:  var(--orange);


}
.the-best-ad{
    margin-top: 4rem;
    background-color: #363d42;
    border-radius: 4rem;
    width: fit-content;
    padding: 20px 13px;
    text-transform: uppercase;
    color: white;
    position: relative;
    display: flex;
    align-items:center;
    justify-content: flex-start;
}
.the-best-ad>span{
    z-index: 2;
}
.the-best-ad>div{
    position: absolute;
    background-color: var(--orange);
    width: 5.4rem;
    height:80%;
    left:8px;
    border-radius: 3rem;
    z-index: 1;
}

.hero-text
{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    text-transform: uppercase;
    font-size: 4.5rem;
    font-weight: bold;
    color: white;
    text-overflow: inherit;
}
.by-me{
    font-size: 1.125rem;
    text-transform: uppercase;
    font-weight: bold;
    text-transform:  none;
    letter-spacing: 1px;
    width: 80%;
}
.hero-buttons{
    display: flex;
    gap: 1rem;
    font-weight: normal;
}
.hero-buttons>:nth-child(1)
{
    color:white;
    background-color: var(--orange);
    width: 8rem;
}

.hero-buttons>:nth-child(2)
{
    color:white;
    background-color: transparent;
    width: 8rem;
    border:  2px solid var(--orange);
}

.right>.btn{
    position:absolute;
    right:3rem;
    top:2rem;
    color: black;
}
.heart-rate{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: var(--darkGrey);
    width:fit-content;
    padding: 1rem;
    align-items: start;
    border-radius:  5px;
    position: absolute;
    right:4rem;
    top: 7rem;
}
.heart-rate>img{
    width: 2rem;
}
.heart-rate>:nth-child(2)
{
 color:var(--gray);

}
.heart-rate>:nth-child(3)
{
 color:white;
 font-size: 1.5rem;
 
}
.hero-image{
position: absolute;
top:10rem;
right:8rem;
width: 23rem;
}
@keyframes beatHeart {
    0% { transform: scale(1.2); }
    50% { transform: scale(1.4); }
    100% { transform: scale(1.1); }
  }
  
  .heart-image {
    animation: beatHeart 1s linear infinite;
  }
.hero-image-back{
    position: absolute;
    top:4rem;
    right:20rem;
    width: 15rem;
    z-index: -1;
    }
.calories
{
display: flex;
gap: 2rem;
background-color: var(--caloryCard);
border-radius: 5px;
padding: 1rem;
width: 205px;
position: absolute;
top: 32rem;
right: 28rem;


}
.calories>img{
    width:3rem;


}
.calories>div{
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}
.calories>div>:nth-child(1)
{
    color: var(--gray);
}
.calories>div>:nth-child(2)
{
    color:white;
    font-size: 1.5rem;
}
@keyframes moveLeftToRight {
    0% { transform: translateX(-10px); }
    50% { transform: translateX(240px); } /* Adjust the distance as needed */
    100% { transform: translateX(-10px); } 
    /* Adjust the distance as needed */
  }
  
  
  .span-left-to-right {
    animation: moveLeftToRight 8s linear infinite;
  }

  @media  screen and (max-width:768px) {
    .home{
        flex-direction: column;
    }
    .the-best-ad{
        margin-top: 0;
        font-size: small;
        align-self: center;
        transform: scale(0.8);
    }
    .hero-text{
        font-size: xx-large;
        align-items: center;
        justify-content: center;
    }
    .by-me{
        font-size: small;
        font-weight: 200;
        letter-spacing: 1px;
        align-items: center;
    }
    .hero-buttons{
        justify-content: center;

    }
    .figure>div>span:nth-of-type(1)
    {
        font-size: large;
    }
    .figure>div>span:nth-of-type(1)
    {
        font-size: small;
    }
 
    .right
    {
       position: relative;
       background: none; 
    }
    .heart-rate{
        left:1rem;
        top:2 rem;
    }
    .calories{
        position:relative;
        top:5rem;
        left:2rem;
    }
   .calories>img{
    width: 2rem;
   }
   .calories>div>:nth-child(2)
   {
    color: white;
    font-size: 1rem;
   }
   .hero-image{
    position:absolute; 
    width:15rem;
    left:7rem;
    top:4rem;
    align-self: center;
   }
   .hero-image-back{
    position:relative; 
    width:15rem;
    left:0rem;
    top:4.5rem;
    align-self: center;
   }
   @keyframes moveLeftToRight {
    0% { transform: translateX(-10px); }
    50% { transform: translateX(180px); } /* Adjust the distance as needed */
    100% { transform: translateX(-10px); } 
    /* Adjust the distance as needed */
  }

  .span-left-to-right {
    animation: moveLeftToRight 6s linear infinite;
  }

  }