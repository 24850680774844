.programs-header{

    display: flex;
    gap:5rem;
    font-weight: bold;
    font-size: 3.25rem;
    justify-content: center;
    color: white;
    text-transform: uppercase;
    font-style: italic;

}
.plans-container{
    margin-top: -4rem;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    gap:4rem;
    position: relative;
}
.plans{
    display: flex;
    align-content: center;
    justify-content: center;
    gap: 3rem;
}
.plan {
    display: flex;
    flex-direction: column;
    background: var(--caloryCard);
    color: white;
    gap: 2rem;
    padding: 1.5rem;
    width: 15rem;
  }
  .plan:nth-child(2)
  {
    background: var(--planCard);
    transform: scale(1.1);

  }
  .plan>svg{
    fill: var(--orange);
    width: 3rem;
    height: 3rem;
    }
    .plan>:nth-child(2){
    font-size: 1rem;
    font-weight: bold;
    }
    .plan>:nth-child(3){
    font-size: 3rem;
    font-weight: bold;
    }
    .plan>:nth-child(5){
    font-size: 0.8rem;
    }
    .features{
     display: flex;
     flex-direction: column;
     gap: 1rem;
     
    }
    .feature{
        display: flex;
        align-items: center;
        gap: 1rem;
        
       }
       .feature>img{
        width: 1rem;
       }
    .plans>:nth-child(2)>svg{
        fill: white;
    }
    .plans>:nth-child(2)>button
    {
        color:orange;
    }
    .last{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding-bottom: 1rem;
    }
    .butt:hover
    {
        color:white;
        background-color: var(--orange);
       

    }
    .plans-blur-1{
        width: 32rem;
        height: 23rem;
        top: 6rem;
        left:0rem;

    }
    .plans-blur-2{
        width: 32rem;
        height: 23rem;
        top: 10rem;
        right:0rem;

    }
    @media screen and (max-width:768px) {
        .programs-header{
            flex-direction: column;
            gap:1rem;
            font-size: xx-large;
            align-items: center;
            justify-content: center;
            margin-top: 2rem;
    
        } 
        .plans{
            flex-direction: column;
            align-items: center;
        }
    
    }