.Footer-container{
    position: relative;
}
.Footer-container>hr{
    border: 1px solid var(--lightgray);
}
.footer{
    padding:  1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    height: 20rem;

}
.social-links{
display: flex;
gap: 4rem;
}
.social-links>a>img{
    width: 3rem;
    height: 3rem;
    cursor: pointer;
}
@media screen and (max-width:768px)
{
    .social-links>a>img{
        width: 2.5rem;
        height: 2.5rem;
        cursor: pointer;
    }


}