.testimonials
{
    display: flex;
    gap: 1rem;
    padding: 0 2rem;
}
.left-t{
    display: flex;
    flex: 1 1;
    gap: 1rem;
    flex-direction: column;
    text-transform:uppercase;
    color: white;
    padding-right: 300px;
    padding-left: 50px; 
 


}
.left-t>:nth-child(1)
{
    color:var(--orange);
    font-weight: bold;
    font-size: 2rem;
}
.left-t>:nth-child(3)
{
    font-size: 3rem;
    font-weight: bold;
}
.left-t>:nth-child(2)
{
    font-size: 2.9rem;
    font-weight: bold;
}
.left-t>:nth-child(4)
{
    text-transform: none;
    text-align: justify;
    letter-spacing: 1.2px;
    line-height: 50px;
    font-size:1.2rem;
}
.right-t
{
    flex: 1;
    position: relative;
}
.right-t>img{
    position:absolute;
    width: 17rem;
    height: 20rem;
    object-fit: cover;
    right: 8rem;
    top: 2rem;
}
.right-t>:nth-child(1)
{
    position: absolute;
    width: 17rem;
    height: 20rem;
    border: 2px solid orange;
    background-color: transparent;
    right: 9rem;
    top: 0.9rem;
}
.right-t>:nth-child(2)
{
    position: absolute;
    width: 17rem;
    height: 19rem;
    right: 7rem;
    top: 4rem;
    background: var(--planCard);
}
.arrows{
    display: flex;
    gap: 1rem;
    position: absolute;
    bottom: 1rem;
    left: 3rem;


}
.arrows>img{
    width:1.5rem;
    cursor: pointer;

}
@media screen and (max-width:768px)
{
    .testimonials{
        flex-direction: row;

    
    }
    .left-t>:nth-child(2),  .left-t>:nth-child(3)
    {
        font-size: xx-large;
    }



.left-t>:nth-child(4)
{
    text-transform: none;
    text-align: justify;
    letter-spacing: 1px;
    line-height: 30px;
    font-size:1rem;
}
.right-t{

display:none
}

}